.notification-card.card{
    border-radius: 9px;
      padding: 5px;
      border:0;
  }
  .nomini-form .card{
    border-radius: 9px;
    padding: 5px;
    border:0;
  }
  .nomini-form .form-group select{
    border:0;
    outline: none;
  }
  .nomini-form  .btn-link{
    border: 0;
    color:#000;
    font-size: 16px;
    text-decoration: none;
    outline: none;
  }
  .nomini-form .btn-primary{
    border:0;
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
    background-image: linear-gradient(to right bottom, #25d49c, #18d592, #0dd587, #0ad57b, #12d56e);
  }

  /**************************************************/
  input#formBasicCheckbox\ green-form-chk-gender-agree {
    position: absolute !important;
    opacity: 0 !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree + label {
    position: relative !important;
    cursor: pointer !important;
    padding: 0 !important;
    text-align: left;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree + label:before {
    content: "" !important;
    margin-right: 10px !important;
    display: inline-block !important;
    vertical-align: text-top !important;
    width: 20px !important;
    position: absolute;
    left:-33px;
    border-radius: 3px;
    border: 1px solid #ddd;
    height: 20px;
    background: #fff;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:hover + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12) !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:checked + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:disabled + label {
    color: #b8b8b8 !important;
    cursor: auto !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:disabled + label:before {
    box-shadow: none !important;
    background: #ddd !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:checked + label:after {
    content: "" !important;
    position: absolute !important;
    left: -28px !important;
    top: 9px !important;
    background: white !important;
    width: 2px !important;
    height: 2px !important;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
     4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white !important;
    transform: rotate(45deg) !important;
  }

  /*************************************************/

  input#formBasicCheckbox\ green-form-chk-agree {
    position: absolute !important;
    opacity: 0 !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree + label {
    position: relative !important;
    cursor: pointer !important;
    padding: 0 !important;
    text-align: left;
  }
  input#formBasicCheckbox\ green-form-chk-agree + label:before {
    content: "" !important;
    margin-right: 10px !important;
    display: inline-block !important;
    vertical-align: text-top !important;
    width: 20px !important;
    position: absolute;
    left:-33px;
    border-radius: 3px;
    border: 1px solid #ddd;
    height: 20px;
    background: #fff;
  }
  input#formBasicCheckbox\ green-form-chk-agree:hover + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12) !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:checked + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:disabled + label {
    color: #b8b8b8 !important;
    cursor: auto !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:disabled + label:before {
    box-shadow: none !important;
    background: #ddd !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:checked + label:after {
    content: "" !important;
    position: absolute !important;
    left: -28px !important;
    top: 9px !important;
    background: white !important;
    width: 2px !important;
    height: 2px !important;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
     4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white !important;
    transform: rotate(45deg) !important;
  }
  
  /*************************/
  
  input#formBasicCheckbox\ green-form-chk {
    display: none;
    position: absolute;
    opacity: 0;
  }
  input#formBasicCheckbox\ green-form-chk + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  input#formBasicCheckbox\ green-form-chk + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    position: absolute;
    left:-33px;
    vertical-align: text-top;
    width: 20px;
    border-radius: 3px;
    border: 1px solid #ddd;
    height: 20px;
    background: #fff;
  }
  input#formBasicCheckbox\ green-form-chk:hover + label:before {
    background: #25d49c;
  }
  input#formBasicCheckbox\ green-form-chk:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  input#formBasicCheckbox\ green-form-chk:checked + label:before {
    background: #25d49c;
  }
  input#formBasicCheckbox\ green-form-chk:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  input#formBasicCheckbox\ green-form-chk:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  input#formBasicCheckbox\ green-form-chk:checked + label:after {
    content: "";
    position: absolute;
    left: -28px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
  .nomini-date button.react-date-picker__calendar-button.react-date-picker__button{
    display: none;
  }
  .nomini-date .react-date-picker__wrapper{
    border:0;
  }
  .nomini-submt-sect{
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    padding-bottom: 35px;
    background-color: #fff;
  }
  .nomini-submt-sect h3{
    background-color: #007bff!important;
    color:#fff;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    font-size: 18px;
    text-transform: capitalize;
    padding:20px;
    font-weight:600;
  }
  .list-nomini-sub {
    padding: 5px 13px;
    border-bottom: 1px solid #f4f7f9;
    margin-bottom: 5px;
}

.list-nomini-sub h4 {
    font-size: 17px;
    font-weight: 600;
   margin-bottom: 3px;
}

.list-nomini-sub h6 {
    font-size: 13px;
    color: #6f7b8b;
}
.list-nomini-sub h6 b{
    color:#000;
}
.list-nomini-sub button.withdraw-btns{
  background-color: #fff;
  border: 2px solid #f16c54;
  color: #f16c54;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.list-nomini-sub button.withdraw-btns:hover{
  background-color: #f16c54;
  color:#fff;
}
.width-position{
  position: relative;
}
.nomini-submt-sect{
  position: absolute;
  top:0;
  right:-35px;
  height:100%;
  min-width: 300px;
  max-width: 800px;
  margin: auto;
}
.nomini-form-width{
  margin-left: -20px;
}
@media (max-width: 1230px){
  .nomini-submt-sect{
    right:-30px;
  }
  .nomini-form-width{
    margin-left: -30px;
  }
}
@media (max-width: 1200px){
  .list-nomini-sub button.withdraw-btns{
    padding: 9px 11px;
  }
  .nomini-submt-sect{
    position: relative;
    right: auto;
    top:auto;
    margin:20px auto 0 auto;
    max-width: 400px;
    height: auto;
    min-width: 320px;
  }
  .nomini-form-width{
    margin-left: 0px;
  }
}
@media (max-width: 600px){
  .list-nomini-sub h6 b{
    display: block;
  }
  .list-nomini-sub button.withdraw-btns{
    margin-top: 10px;
  }
}