.timer-box .r-block{
    background: #FF4141;
    color: #ffffff;
    width: 40px;
    margin: 0 auto;
    font-weight: bold;
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
} 
.timer-box{
    color: #FF4141;
    font-size: 13px;
} 
.timer-box .colon{
    font-weight: bold;
    font-size: 25px;
}