.voting-left.card {
    padding: 0;
    display: block !important;
    border-radius: 9px;
    border: 0;
}

.voting-left.card .card-body {
    padding: 0;
    display: block;
    border-radius: 9px !important;
}
.voting-left.card .card-body .nav-pills .nav-link.active,.voting-left.card .card-body .nav-pills .show>.nav-link{
    background:#d6e8ff;
    display: block;
    border-top-left-radius: 9px !important;
    border-top-right-radius: 9px !important;
    color:#000;
}
.voting-left .card-header-pills{
    margin:0;
}
.voting-left .nav-pills .nav-link{
    color:#000;
    font-size: 14px;
    padding:20px 20px;
    border-bottom: 1px solid #f4f6f9;
}
.green-btns button{
background: #fff;

color: #13d570;
border:1px solid #13d570;
padding:8px 30px;
}
.green-btns button:hover{
    border:0;
    color:#fff;
    background: #13d570;
}
.green-btns button.active {
    background: #13d570 !important;
    border: 0;
}
.save-blue-btn button{
    font-size: 16px;
    padding:6px 33px !important;
}
.submit-btn{
    background: #ef5732;
    padding:6px 22px;
    font-size: 18px;
    border:0;
    border-radius:10px;
}
.voting-sect .fade {
    height: auto;
 }
.voting-sect .fade:not(.show) {
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;

}
.voting-sect .card{
    border:0;
    border-radius: 9px;
}
.vote-height-over{
    height: 620px !important;
    overflow-x: hidden;
    overflow-y: scroll;

}