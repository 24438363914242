/**********************slider page********************************/
.president-slid {
    background: #fff;
    padding: 50px 55px;
    border-radius: 10px;
  }
  .president-hds {
    text-align: center;
    margin: 0px 0 30px 0;
    width: 100%;
    position: relative;
  }
  .president-hds span {
    background: #f5f6f8;
    color: #000;
    padding: 10px 25px;
    display: inline-block;
    border-radius: 17px;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    z-index: 9;
    font-size: 20px;
  }
  .president-hds span i {
    color: #a5abb8;
  }
  .president-hds:before {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    left: 0;
    /* padding-top: 0;  */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .president-hds:after {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    right: 0;
    /* padding-top: 0; */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .other-list {
    text-align: center;
    width: 100%;
    position: relative;
  }
  .other-list span {
    background: #fff;
    color: #868791;
    padding: 10px 25px;
    display: inline-block;
    border-radius: 17px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    z-index: 9;
    font-size: 18px;
  }
  .other-list:before {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    left: 0;
    /* padding-top: 0;  */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .other-list:after {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    right: 0;
    /* padding-top: 0; */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .president-slid .carousel-indicators {
    display: none;
  }
  .president-slid .carousel-control-prev {
    width: 40px;
    background: #eeeeee;
    height: 40px;
    top: 50%;
    left: -45px;
    opacity: 1;
    border-radius: 50px;
  }
  .president-slid .carousel-control-next {
    width: 40px;
    background: #eeeeee;
    height: 40px;
    opacity: 1;
    top: 50%;
    border-radius: 50px;
    right: -45px;
  }
  .president-slid .carousel-control-next-icon {
    background: url("../assets/arrow.png") no-repeat !important;
  }
  .president-slid .carousel-control-prev-icon {
    background: url("../assets/arrow.png") no-repeat !important;
    transform: rotate(180deg);
  }
  .president-slid .carousel-control-next-icon,
  .president-slid .carousel-control-prev-icon {
    display: inline-block;
    width: 10px;
    height: 16px;
  }
  .blue-over-img img {
   width: 190px;
   object-fit: cover;
    height: 250px;
    border-radius: 10px;
  }
  .crown-above {
    position: absolute;
    top: 5px;
    width: 35px;
    height: 35px;
    right: 5px;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
  }
  
  .crown-above img {
    width: 25px;
    height: 25px;
  }
  .bg-blue-slid {
    background: #3482e8;
    color: #fff;
    padding: 19px;
    position: relative;
    border-radius: 10px;
    margin-top: 25px;
    height: 200px;
  }
  
  .blue-over-img {
   margin-left: 10px;
    margin-top: -44px;
    position: relative;
  }
  
  
  
  .bg-blue-slid h4 {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 21px;
    margin-top: 10px;
  }
  
  .bg-blue-slid h6 {
    font-size: 13px;
    line-height: 10px;
    font-weight: 100;
  }
  
  .total-count {
    margin-top: 20px;
    font-size: 24px;
    position: relative;
    font-weight: 600;
    line-height: 21px;
    padding-right: 19px;
    padding-left: 19px;
   
  }
  
  .total-count span {
    font-size: 13px;
    font-weight: 100;
  }
  .left-spc{
    margin-left:30px;
  }
  .total-count:after {
    content: "";
  position: absolute;
  right: 0;
  background: #fff;
  height: 100%;
  top: 0;
  width: 1px;
  }
  .total-count:first-child {
    padding-left: 0;
  }
  .total-count:last-child:after {
    content: none;
  }
  .slider-sub-names img {
    border-radius: 50%;
    width: 50px;
    object-fit: cover;
    height: 50px;
  }
  .slider-sub-names h4 {
    font-size: 17px;
    margin-top: 0;
  }
  
  .slider-sub-names h5 {
    text-align: right;
    margin-top: 20px;
    font-size: 18px;
  }
  
  .slider-sub-names h6 {
    font-size: 13px;
    line-height: 7px;
  }
  
  .slider-sub-names {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 5px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
  .slider-sub-names:last-child {
    border-bottom: 0px;
  }
  @media (max-width: 1200px){
    .president-hds:before, .president-hds:after{
    width: 35%;
    }
    .left-spc {
      margin-left: 8px;
  }
    }
    @media (max-width: 991px){
      .blue-over-img{
        margin-left: 0;
      }
      .slider-sub-names h6 {
        line-height: 16px;
    }
    }
    @media  (max-width:991px){
  
      .slider-left{
        display: block !important;
        color:#000;
        text-align: center;
      }
      .bg-blue-slid .crown-above{
        right:10px;
      }
      .bg-gray-slid .crown-above{
        right:28px;
      }
    }
    @media (max-width: 768px){
      .president-slid {
        padding: 50px 20px;
      }
      .slider-right-list{
        margin-bottom: 25px;
      }
      .other-list{
        margin-top:40px;
      }
    .president-hds:before, .president-hds:after{
    width: 30%;
    }
    .president-slid .carousel-control-prev,
    .president-slid .carousel-control-next {
      display: none;
        }
        .president-slid .carousel-indicators{
          display:flex;
        }
        .carousel-indicators .active {
          background-color: #007bff !important;
      }
      .carousel-indicators li{
        background-color: #007bff !important;
      }
    }
  
    @media (max-width: 991px){
      .total-count{
        margin-top: 0;
      }
        .blue-over-img{
    width: 200px;
    margin: -44px auto 0 auto !important;
  }
      .slider-left{
        display: block !important;
        color:#000;
        text-align: center;
      }
      .left-spc{
        display: block;
        float: left;
        width: 100%;
      }
  
    }
    @media (max-width: 480px){
      slider-sub-names h6 {
        line-height: 15px;
    }
      .crown-above {
        right: 10px;
    }
    .bg-gray-slid .crown-above{
      right:10px;
    }
    .blue-over-img{
    width: 200px;
    margin: -44px auto 0 auto;
  }
    }
    @media (max-width: 380px){
      .slider-sub-names h4{
        font-size: 14px;
      }
      .slider-sub-names h5{
        margin-top: 5px;
        font-size: 14px;
      }
      .blue-over-img{
        width: 100%;
        margin: -44px auto 0 auto;
      }
      .blue-over-img img {
        width: 100%;
      }
    }
  
    /*****08-06-2023*************************/
    .bg-gray-slid .blue-over-img img {
      width: 120px;
      height: 130px;
      object-fit: cover;
     }
     .bg-gray-slid  .blue-over-img {
      margin-left: 10px;
      margin-top: -32px;
     }
     .bg-gray-slid {
      background: #f2f5fb;
      color: #000;
      padding: 19px;
      position: relative;
      border-radius: 10px;
      margin-top: 25px;
      height: 106px;
      display: block;
      float: left;
      width: 100%;
      margin-bottom: 15px;
  }
  
  .bg-gray-slid h4 {
    font-size: 17px;
  }
  
  .bg-gray-slid .left-spc {
    margin-left: 10px;
  }
  
  .bg-gray-slid h6 {
    font-size: 13px;
    font-weight: 100;
  }
  
  .bg-gray-slid .total-count {
    margin-top: 6px;
    text-align: right;
    padding-right: 8px;
  }
  .bg-gray-slid .crown-above img {
    width: 25px !important;
    height: 25px !important;
  }
  .grey-height-over{
    height: 439px !important;
    padding-right: 15px;
      overflow-y: scroll;
  }
  @media (max-width: 1200px){
    .bg-gray-slid .blue-over-img {
      margin-left: 0px;
   
  }
  }
  @media  (max-width: 991px){
    .bg-gray-slid{
      height: auto;
    }
    .bg-gray-slid .total-count {
      text-align: center;
    }
    .gray-over-img {
      width: 200px;
      margin: -32px auto 0 auto;
  }
    .bg-gray-slid{
      height: auto;
    }
    .bg-gray-slid .blue-over-img img {
      width: 153px;
      height: 180px;
  }
  
  }
  @media (max-width: 380px){
    .bg-gray-slid .slider-sub-names h4{
      font-size: 14px;
    }
    .bg-gray-slid .slider-sub-names h5{
      margin-top: 5px;
      font-size: 14px;
    }
    .bg-gray-slid .blue-over-img{
      width: 100%;
    }
    .bg-gray-slid .blue-over-img img {
      width: 100%;
    }
  }
  .scrollbar {
    overflow-y: scroll;
  }
  .scrollbar-primary {
    scrollbar-color: #c4cee0 #f5f5f5;
  }
  .scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
   }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c4cee0; 
  }