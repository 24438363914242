.grid-row{
   margin-top: 40px;
   position: relative;
}
.grid-link svg{
   width: 46px !important;
   margin-bottom: 12px;
}
.grid-link .badge{
    top: 47%;
    border-radius: 50%;
    margin-top: -45px;
    margin-left: 20px;
    height: 22px;
    width: 22px;
    line-height: 17px;
}

.blue-land-btns button{
   border-radius: 9px;
}