@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap");
body {
  background-color: #f2f5fb !important;
  font-family: "Poppins", sans-serif;
}
.middle-wrap {
  min-height: 600px;
}
.theme-styled-group {
  border-bottom: 1px solid #eaeef4;
}
.theme-styled-group .form-control,
.theme-styled-group .form-control:disabled {
  border: none;
  background: #fff;
  padding-left: 0;
  padding-top: 0;
}
.theme-styled-group .form-control {
  font-weight: bold !important;
}
.theme-styled-group .form-control:focus {
  outline: none;
  box-shadow: none;
}
.theme-styled-group .form-control:disabled {
  font-weight: normal !important;
}
.theme-styled-group .form-group {
  margin: 0 0 0 0;
}
.theme-styled-group .form-group label {
  margin: 0 0 0 0;
  color: #93999f;
}
.submit-btn {
  right: 40px;
  top: 50px;
  z-index: 1;
} 
.assosiate-box{
  border-radius: 9px;
background: #f2f5fb !important;
border:0;
}
.home-modals .modal-header {
  position: relative;
  border: 0;
}

.home-modals button.close {
  position: absolute;

  right: 0;
  color: #007bff;
  font-weight: 100;
  top: 0;
  border-top-right-radius: 9px;
  opacity: 1;
  padding: 6px !important;
  margin: 0;
}

.home-modals .modal-content {
  border: 0;
  border-radius: 9px;
}

.home-modals .modal-title.h4 {
  font-weight: 600;
  margin-top: 10px;
}

.home-modals .modal-footer {
  border: 0;
}

.home-modals .modal-body {
  font-size: 14px;
}

.home-modals button.btn.btn-primary {
  padding: 10px 35px;
  margin-bottom: 21px;
}
.contnt-center{
  display: block;
  padding-bottom: 0 !important;
  text-align: center;
}
.check-circle-img{
  margin-top: 10px;
}
.noted-list-pop ul {
  padding: 0;
  text-align: left;
  list-style: none;
}

.noted-list-pop {
  text-align: center;
  max-width: 355px;
  margin: auto;
  width: max-content;
}
.noted-list-pop ul li{
  line-height: 3em;
  font-size: 14px;
  color: #ef5632;
}
.noted-list-pop ul li svg{
  margin-right: 15px;
}
.submit-btn-orange{
  background: #ef5732;
  padding: 8px 60px !important;
  font-size: 18px;
  border: 0;
  border-radius: 10px;
  margin-bottom: 15px !important;
}
.submit-btn-orange:hover{
background: #bd2130;
}
button.back-btn-orange.btn.btn-primary {
  color: #ef5732;
  background: transparent;
  border: 0;
  font-size: 15px;
  padding: 0;
  text-decoration: underline;
}
button.back-btn-orange.btn.btn-primary:hover{
  opacity: .6;
}
.divaddress {font-size: 13px;color: #6b6b6b;text-align: center;min-height: 40px;}
.dminHeight{min-height: 50px;}
.green-bgbtns button {
  background: #13d570 !important;
  color: #ffffff !important;
  border: 1px solid #13d570;
  padding: 8px 30px;
}
.bgsuccess{
  background: #d5f1e2;
  color: #38a76d!important;
}
.bgwarning
{
  background: #ffdcdc;
  color: #f16647!important;
}
/*-------------------------------------------------------------------*/

/* === Landing screens === */

/* Auth */

.auth .lock-profile-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.auth .auth-form-light {
  background: #ffffff;
}

.auth .auth-form-light select {
  color: #c9c8c8;
}

.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .asColorPicker-wrap .form-control:focus,
.auth .auth-form-light .input-group .asColorPicker-input:focus,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:focus,
.auth .auth-form-light .input-group .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .input-group select:focus,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="text"]:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="text"]:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  select:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  select:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="number"]:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="number"]:focus,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-light
  .input-group
  .select2-selection--single:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-selection--single:focus,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .input-group
  .select2-search__field:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-search__field:focus,
.auth .auth-form-light .input-group .typeahead:focus,
.auth .auth-form-light .asColorPicker-wrap .typeahead:focus,
.auth .auth-form-light .input-group .tt-query:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-query:focus,
.auth .auth-form-light .input-group .tt-hint:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:focus,
.auth .auth-form-light .input-group .form-control:active,
.auth .auth-form-light .asColorPicker-wrap .form-control:active,
.auth .auth-form-light .input-group .asColorPicker-input:active,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:active,
.auth .auth-form-light .input-group .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .input-group select:active,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="text"]:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="text"]:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  select:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  select:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="number"]:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="number"]:active,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-light
  .input-group
  .select2-selection--single:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-selection--single:active,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .input-group
  .select2-search__field:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-search__field:active,
.auth .auth-form-light .input-group .typeahead:active,
.auth .auth-form-light .asColorPicker-wrap .typeahead:active,
.auth .auth-form-light .input-group .tt-query:active,
.auth .auth-form-light .asColorPicker-wrap .tt-query:active,
.auth .auth-form-light .input-group .tt-hint:active,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:active {
  border-color: #eee;
}

.auth .auth-form-transparent {
  background: transparent;
}

.auth .auth-form-transparent .form-control,
.auth .auth-form-transparent .asColorPicker-input,
.auth .auth-form-transparent .dataTables_wrapper select,
.dataTables_wrapper .auth .auth-form-transparent select,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"],
.auth .auth-form-transparent .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .auth .auth-form-transparent select,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"],
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field,
.auth .auth-form-transparent .typeahead,
.auth .auth-form-transparent .tt-query,
.auth .auth-form-transparent .tt-hint,
.auth .auth-form-transparent .input-group-text {
  border-color: #a3a4a5;
}

.auth .auth-form-transparent .form-control:focus,
.auth .auth-form-transparent .asColorPicker-input:focus,
.auth .auth-form-transparent .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-transparent select:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"]:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  select:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"]:focus,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single:focus,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field:focus,
.auth .auth-form-transparent .typeahead:focus,
.auth .auth-form-transparent .tt-query:focus,
.auth .auth-form-transparent .tt-hint:focus,
.auth .auth-form-transparent .form-control:active,
.auth .auth-form-transparent .asColorPicker-input:active,
.auth .auth-form-transparent .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-transparent select:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"]:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  select:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"]:active,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single:active,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field:active,
.auth .auth-form-transparent .typeahead:active,
.auth .auth-form-transparent .tt-query:active,
.auth .auth-form-transparent .tt-hint:active,
.auth .auth-form-transparent .input-group-text:focus,
.auth .auth-form-transparent .input-group-text:active {
  border-color: #a3a4a5;
}

.auth .auth-form-transparent select {
  outline-color: #a3a4a5;
}

.auth.auth-img-bg {
  padding: 0;
}

@media (min-width: 768px) {
  .auth.auth-img-bg .auth-form-transparent {
    width: 55%;
    margin: auto;
  }
}

.auth .brand-logo {
  margin-bottom: 2rem;
}

.auth .brand-logo img {
  width: 100%;
}

.auth form .form-group {
  margin-bottom: 1.5rem;
}

.auth form .form-group label {
  font-size: 0.8125rem;
}

.auth form .form-group .form-control,
.auth form .form-group .asColorPicker-input,
.auth form .form-group .dataTables_wrapper select,
.dataTables_wrapper .auth form .form-group select,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="text"],
.auth form .form-group .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .auth form .form-group select,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="number"],
.auth form .form-group .select2-container--default .select2-selection--single,
.select2-container--default .auth form .form-group .select2-selection--single,
.auth
  form
  .form-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth
  form
  .form-group
  .select2-search__field,
.auth form .form-group .typeahead,
.auth form .form-group .tt-query,
.auth form .form-group .tt-hint {
  background: transparent;
  border-radius: 0;
  font-size: 0.9375rem;
}

.auth form .auth-form-btn {
  height: 50px;
  line-height: 36px;
}

.auth form .auth-link {
  font-size: 0.875rem;
}

.auth form .auth-link:hover {
  color: initial;
}

.page-body-wrapper {
  min-height: calc(100vh - 59px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0px;
}

.page-body-wrapper.full-page-wrapper {
  width: 100%;
  min-height: 100vh;
  padding-top: 0;
}

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
    width: calc(100% - 259px);
    min-height: calc(100vh - 59px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin-left:239px
}

@media (max-width: 991px) {
  .main-panel {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: #fafafa;
  padding: 1.1rem 2.5rem 2.5rem;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .content-wrapper {
    padding: 1.5rem 1.5rem;
  }
}
.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  border-color: #ced4da !important;
  padding: 9px !important;
  color: #c9c8c8 !important;
  border-left: none;
  border-radius: 0px 3px 3px 0px !important;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-prepend .fc button,
.fc .input-group-prepend button,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload,
.input-group-prepend .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .input-group-prepend .swal2-styled,
.input-group-prepend .wizard > .actions a,
.wizard > .actions .input-group-prepend a,
.input-group-append .btn,
.input-group-append .fc button,
.fc .input-group-append button,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload,
.input-group-append .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .input-group-append .swal2-styled,
.input-group-append .wizard > .actions a,
.wizard > .actions .input-group-append a {
  position: relative;
  z-index: 2;
}
.input-group-text1 {
  display: flex;
  align-items: center;
  padding: 0.875rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  align-items: center;

}
.mandatory{
  color: #ff374a;
}
.mandatoryBorder{
  border-bottom: #ff374a solid 1px!important;
}
.borderNone{
  border: none;
  outline: none;
}
.alignCenter{
  text-align: center;
}
.nomini-submt-withdraw {
  position: relative!important;
}
.nomini-submt-withdraw> h3 {
  text-align: center!important;
}
.btnbottom {
  bottom: 25px;
  position: absolute;
  width: 100%;
  margin-left: -1.25rem;
}
.assosiate-box{
height:100%!important
}
.divcardbody{
  margin-bottom:55px;
}
.imgdiv{
  height: 120px;
  width: 120px!important;
}
.divoverflowY{
  overflow-x: hidden;
  overflow-y: auto;
}
input, .form-control:focus, .asColorPicker-input:focus, .dataTables_wrapper select:focus, .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"]:focus, .jsgrid .jsgrid-table .jsgrid-filter-row select:focus, .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"]:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus, .tt-query:focus, .tt-hint:focus, input:focus, select:focus, textarea:focus, button:focus
{
  outline: none !important;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none!important;
  outline-style: none;
}
.elect-text {
  font-weight: bold;
  font-size: 24px;
  color:rgb(62, 60, 79);
  font-family: Roboto, sans-serif;
}
.textDec {
  font-size: 14px;
  font-weight: bold;
  color:rgb(62, 60, 79);
}
.form-control-lg {
  height: 3.5rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.no_selection {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.min-btn-width{
  min-width: 95px;
}
.min-btn-height
{
  min-height: 50px;
  padding: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.margin5{
  margin-bottom: .5rem!important;
}
.hidediv{
  visibility: hidden;
}
.iconMinWidth{
  min-width: 24px;
}
.iconMinWidth2{
  min-width: 20px;
}
.cursotpointer{
  cursor:pointer;
}
.cursotpointer:hover{
  text-decoration:none!important;
}
.display-none{
  display: none !important;
}
.display-block{
  display: block;
}
.linkbtn{
  color:#007bff!important;
  cursor: pointer!important;
  text-decoration: none!important;
}
.linkbtn:hover{
  color:#075eba!important;
  text-decoration: none!important;
}
.withdrawed-btns {
  background-color: #e53414;
  color: #fff;
  border: 2px solid #e93717;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.rejectionNote{
  display: inline-flex;
  font-size: 13px;
}
.nomargin{
  margin-bottom: 0PX;;
}
.popupmsg{
  margin-top: 20px!important;
  font-size: 20px;
  line-height: 1.2;
  }
.popupfooter
{
    justify-content: center!important;
    margin-top: 35px!important;
}
.btnMargin{
margin-bottom: 30px;
}
.warning-orange
{
  background-color: #ff7600!important;
    border: 2px solid #ff7600!important;
    color: #fff;
    font-size: 13px;
    border-radius: 9px;
    font-weight: 600;
    float: right;
    display: block;
    padding: 7px 6px;
    text-transform: uppercase;
    min-width: 106px;
}
.Approved-btns {
  background-color: #2f7642;
  border: 2px solid #2f7642;
  color: #ffffff;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.Pending-btns {
  background-color: #ffad18;
  border: 2px solid #ffad18;
  color: #ffffff;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.noDataMsg{
  margin-left: 20px;
    margin-top: 20px;
}
.b {
  font-weight: bold !important;
}
#notfounddiv .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound p {
font-family: montserrat,sans-serif;
color: #000;
font-size: 14px;
font-weight: 400;
margin-bottom: 20px;
margin-top: 0;
font-size: 20px;
}
#notfounddiv {
top: 50%;
left: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%) translateX(-50%);
width: 100%;
position: absolute;
}
.min-height-unset{
  min-height: unset!important;
}
.wraptxt{
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.divGray{
  background: #647a95!important;
  color: #ffffff!important;
  }
.size25{
  font-size: 25px;
  }
.divInline{
  display: inline-flex;
  }
.maxVoteDiv{
  background: #ffdcdc;
  color: #f16e47;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-right: 10px;
 }
 .LoginInput{
  background: transparent;
  border-radius: 0;
  font-size: 0.9375rem;
 }